import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import QlikQ from "../images/qlik-testimonial-ma.png"
import Shapefour from "../images/bannerElement/white-design-element-automation.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import AnalyticsReporting from "../images/icons/MarketingAutomation/Analytics-reporting.svg"
import AnalyzingSTEP from "../images/icons/MarketingAutomation/Analyzing-STEP.svg"
import CampaignExecution from "../images/icons/MarketingAutomation/Campaign-execution.svg"
import DatabaseOptimization from "../images/icons/MarketingAutomation/Database-optimization.svg"
import MarketingAutomationDeploy from "../images/icons/MarketingAutomation/Marketing-Automation-Deploy.svg"
import MarketingAutomationDesign from "../images/icons/MarketingAutomation/Marketing-Automation-Design.svg"
import MarketingAutomationDevelop from "../images/icons/MarketingAutomation/Marketing-Automation-Develop.svg"
import MarketingAutomationDrive from "../images/icons/MarketingAutomation/Marketing-Automation-Drive.svg"
import SeamlessPlatformImplementation from "../images/icons/MarketingAutomation/Seamless-platform-implementation.svg"
import StrategicConsulting from "../images/icons/MarketingAutomation/Strategic-consulting.svg"
import image1 from "../images/image-one.svg"
import image3 from "../images/image-three.svg"
import image2 from "../images/image-two.svg"
import Conversica from "../images/logos/MarketingAutomation/Group18.svg"
import ProudMoment from "../images/logos/MarketingAutomation/Group53.svg"
import Fujitsu from "../images/logos/MarketingAutomation/Group55.svg"
import OpenPrise from "../images/logos/MarketingAutomation/Group56.svg"
import Entrust from "../images/logos/MarketingAutomation/Group57.svg"
import Acalvio from "../images/logos/MarketingAutomation/Group58.svg"
import partners from "../images/ma-partners-tools-1.svg"

import "../styles/404.css"

const MarketingAutomation = ({ siteTitle }) => {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Marketing Automation | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="marketing-automation demand-generation">
          <section id="Banner">
            <div class="container">
              <div class="banner-holder">
                <div class="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div class="title">
                  <div class="banner-title">
                    <h1>Streamline marketing, maximize results.</h1>
                    <p>
                      Harness the power of automation to elevate your campaigns
                    </p>
                    <a
                      className="button"
                      id="3dsolutionvideo"
                      href="/contact-us/"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <h2>Automation tools for high-impact marketing</h2>
              <p>
                Free up your team from tedious tasks and focus on high-value
                strategy. Our experts seamlessly integrate the right marketing
                automation tools to drive exceptional results.
              </p>
            </div>
          </section>
          <section id="WeDeliver">
            <div class="container">
              <h2>Our expertise</h2>
              <div class="horizontalImageIconSec">
                <div class="moduleWrap">
                  <div class="eachModule">
                    <div class="icons">
                      <img width="100" height="100" src={StrategicConsulting} />
                    </div>
                    <div class="contents">
                      <h3>Strategic Consulting</h3>
                      <p>
                        Develop a customized automation plan aligned to your
                        unique goals.
                      </p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img
                        width="100"
                        height="100"
                        src={DatabaseOptimization}
                      />
                    </div>
                    <div class="contents">
                      <h3>Database Optimization</h3>
                      <p>
                        Maintain a clean, targeted database for effective
                        communication.
                      </p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img
                        width="100"
                        height="100"
                        src={SeamlessPlatformImplementation}
                      />
                    </div>
                    <div class="contents">
                      <h3>Seamless Platform Implementation</h3>
                      <p>
                        We'll select and set up the perfect toolset for your
                        needs.
                      </p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img width="100" height="100" src={AnalyticsReporting} />
                    </div>
                    <div class="contents">
                      <h3>Analytics &amp; Reporting</h3>
                      <p>
                        Gain actionable insights to refine your strategy
                        continuously.
                      </p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img width="100" height="100" src={CampaignExecution} />
                    </div>
                    <div class="contents">
                      <h3>Campaign Execution</h3>
                      <p>
                        Design and launch high-converting nurture campaigns and
                        workflows.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="Highlights">
            <div class="container">
              <div class="wrapper">
                <h2>Unlock next-level automation with AI</h2>
                <p>
                  Utilizing AI, we personalize experiences, forecast behaviors,
                  and simplify decision-making. AI enhances automation by:
                </p>
                <ul class="bullet-point">
                  <li>Delivering custom-tagged content and offers</li>
                  <li>
                    Utilizing predictive models to understand customer
                    preferences better
                  </li>
                  <li>Optimizing campaign timing and messaging</li>
                  <li>
                    Automatically scoring leads, segmenting, and nurturing them
                  </li>
                  <li>
                    Identifying high-value opportunities through buyer signal
                    analysis
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section id="Process">
            <div class="container">
              <h2>Our 5D process</h2>
              <p>
                We understand that effective marketing automation is equal parts
                strategy and execution. That's why we follow a proven,
                end-to-end process meticulously designed to maximize your ROI
                from automation. Each step builds upon the next, ensuring a
                seamless journey from initial discovery to continual
                optimization and revenue growth.
              </p>
              <div class="multiStageProcess">
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="154" height="154" src={AnalyzingSTEP} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image1} />
                    </span>
                  </div>
                  <div class="processContent">Discover</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img
                      width="100"
                      height="100"
                      src={MarketingAutomationDesign}
                    />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">Design</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img
                      width="154"
                      height="154"
                      src={MarketingAutomationDevelop}
                    />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">Develop</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img
                      width="154"
                      height="154"
                      src={MarketingAutomationDeploy}
                    />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">Deploy</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img
                      width="160"
                      height="160"
                      src={MarketingAutomationDrive}
                    />
                    <span class="borderImg">
                      <img width="160" height="161" src={image3} />
                    </span>
                  </div>
                  <div class="processContent">Drive</div>
                </div>
              </div>
            </div>
          </section>
          <section id="ParallexSection" class="parallex1">
            <div class="holder">
              <div class="container">
                <div class="TextImgSection">
                  <div class="text-image-holder">
                    <div class="text-wrap">
                      <div class="animation-text">
                        <div>
                          <h3>Partners/Tools</h3>
                        </div>
                        <div>
                          <p>
                            We partner with leading marketing automation
                            companies and utilize advanced tools. We choose the
                            best fit to solve your business problems, help you
                            exceed your marketing goals and stay ahead in the
                            industry.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mobile-img">
                      <img
                        alt=" MA Partners"
                        width="550"
                        height="550"
                        src={partners}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="ParallexSection" class="parallex2">
            <div class="holder">
              <div class="container">
                <div class="testimonial-wrapper">
                  <div class="left-content">
                    <div class="animation-text">
                      <span class="quote"></span>
                      <div>
                        <p>
                          The team of experts at Position<sup>2</sup> assigned
                          The team of experts at Position<sup>2</sup> assigned
                          to our account have demonstrated on numerous occasions
                          their grasp of marketing automation best practices and
                          have assisted us in implementing these across several
                          projects.{" "}
                        </p>
                      </div>
                      <div class="testi-designation">
                        <p class="designation">
                          Manager, Campaign Advisory Services
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mobile-img">
                    <img alt="Qlik Image" width="500" height="500" src={QlikQ} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PartnerLogoSection">
            <div class="container">
              <div class="logo-wrap">
                <div class="each-logo">
                  <img src={Conversica} alt="Cohere" />
                </div>
                <div class="each-logo">
                  <img src={ProudMoment} alt="Lenovo" />
                </div>
                <div class="each-logo">
                  <img src={Fujitsu} alt="Coveo" />
                </div>
                <div class="each-logo">
                  <img src={OpenPrise} alt="Amex" />
                </div>
                <div class="each-logo">
                  <img src={Entrust} alt="Macroair" />
                </div>
                <div class="each-logo">
                  <img src={Acalvio} alt="Airbnb" />
                </div>
              </div>
            </div>
          </section>
          <section id="contactus-section">
            <div class="container">
              <h2>Ready to unlock the power of Marketing Automation?</h2>
              <a className="button" id="3dsolutionvideo" href="/contact-us/">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

MarketingAutomation.propTypes = {
  siteTitle: PropTypes.string,
}

MarketingAutomation.defaultProps = {
  siteTitle: ``,
}

export default MarketingAutomation
